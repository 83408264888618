import ReactDOM from 'react-dom/client'
import { App } from './App'
import type { AppProps } from './App'
import type { Environment, Theme, TopbarMenu, Delegations } from './config'
import pkg from '../package.json'

const JSONParse = (data: string | undefined) => {
  try {
    return JSON.parse(data as string)
  } catch (e) {
    return undefined
  }
}

class FarmacloudHeaderBar extends HTMLElement {
  props: AppProps
  renderer: any

  constructor() {
    super();
    this.props = this.getPropsFromDataset()
    this.attachShadow({ mode: 'open' })
  }

  connectedCallback() {
    console.log('farmacloud-bar: ', pkg.version)
    this.props = this.getPropsFromDataset()
    this.render()
  }

  render () {
    this.renderer = this.renderer || ReactDOM.createRoot(this.shadowRoot as ShadowRoot)
    this.renderer.render(<App {...this.props} />)
  }

  getPropsFromDataset () {
    return {
      hide: this.dataset.initialstate !== 'show',
      env: (this.dataset.env as Environment) || ('dev' as Environment),
      token: undefined,
      freeText1: this.dataset.freetext1 || undefined,
      freeText2: this.dataset.freetext2 || undefined,
      showDateTime: this.dataset.datetime === 'true',
      showHelp: this.dataset.help === 'true',
      showConfiguration: this.dataset.config === 'true',
      showLocker: this.dataset.locker === 'true',
      showFindEngine: this.dataset.findengine === 'true',
      showFindEngineTextBox: this.dataset.findenginetextbox === 'true',
      showShoppingCart: this.dataset.shoppingcart === 'true',
      shoppingCartItems: parseInt(this.dataset.shoppingcartitems || '0'),
      showFavorites: this.dataset.favorites === 'true',
      encodeFindEngineText: this.dataset.encodefindenginetext === 'true',
      primaryColor: this.dataset.primarycolor || undefined,
      theme: (this.dataset.theme as Theme) || ('dark' as Theme),
      dropdownMenu: JSONParse(this.dataset.dropdownmenu),
      delegations: JSONParse(this.dataset.delegations),
      secured: this.dataset.secured === 'true'
    }
  }

  // PUBLIC METHODS

  hide (): void {
    this.props.hide = true
    this.render()
  }

  show (): void {
    this.props.hide = false
    this.render()
  }

  reload (): void {
    this.render()
  }

  logout (): void {
    window.dispatchEvent(new CustomEvent('onLogout', { composed: true }))
    this.props.token = undefined
    this.render()
  }

  setToken (token: { token: string }): void {
    this.props.token = token.token
    this.render()
  }

  setFreeText1 (freeText1: string): void {
    this.props.freeText1 = freeText1
    this.render()
  }

  removeFreeText1 (): void {
    this.props.freeText1 = undefined
    this.render()
  }

  setFreeText2 (freeText2: string): void {
    this.props.freeText2 = freeText2
    this.render()
  }

  removeFreeText2 (): void {
    this.props.freeText2 = undefined
    this.render()
  }

  setShowDateTime (showDate: boolean): void {
    if (this.props.showDateTime === showDate) return
    this.props.showDateTime = showDate
    this.render()
  }

  setHelp (showHelp: boolean): void {
    if (this.props.showHelp === showHelp) return
    this.props.showHelp = showHelp
    this.render()
  }

  setConfig (showConfiguration: boolean): void {
    if (this.props.showConfiguration === showConfiguration) return
    this.props.showConfiguration = showConfiguration
    this.render()
  }

  setLocker (showLocker: boolean): void {
    if (this.props.showLocker === showLocker) return
    this.props.showLocker = showLocker
    this.render()
  }

  setFindEngine (showFindEngine: boolean): void {
    if (this.props.showFindEngine === showFindEngine) return
    this.props.showFindEngine = showFindEngine
    this.render()
  }

  setFindEngineTextBox (showFindEngineTextBox: boolean): void {
    if (this.props.showFindEngineTextBox === showFindEngineTextBox) return
    this.props.showFindEngineTextBox = showFindEngineTextBox
    this.render()
  }

  setEncodeFindEngineText (encodeFindEngineText: boolean): void {
    if (this.props.encodeFindEngineText === encodeFindEngineText) return
    this.props.encodeFindEngineText = encodeFindEngineText
    this.render()
  }

  setShoppingCart (showShoppingCart: boolean): void {
    if (this.props.showShoppingCart === showShoppingCart) return
    this.props.showShoppingCart = showShoppingCart
    this.render()
  }

  setShoppingCartItems (shoppingCartItems: number): void {
    if (this.props.shoppingCartItems === shoppingCartItems) return
    this.props.shoppingCartItems = Math.abs(shoppingCartItems)
    this.render()
  }

  setPrimaryColor (primaryColor: string): void {
    if (this.props.primaryColor === primaryColor) return
    this.props.primaryColor = primaryColor
    this.render()
  }

  setTheme (theme: Theme): void {
    this.props.theme = theme
    this.render()
  }

  setFavorites (showFavorites: boolean): void {
    if (this.props.showFavorites === showFavorites) return
    this.props.showFavorites = showFavorites
    this.render()
  }

  setDropdownMenu (data: TopbarMenu): void {
    this.props.dropdownMenu = data
    this.render()
  }

  setDelegations (delegations: Delegations): void {
    this.props.delegations = delegations
    this.render()
  }

  setSecured (secured: boolean): void {
    if (this.props.secured === secured) return
    this.props.secured = secured
    this.render()
  }
}

window.customElements.define('farmacloud-header-bar', FarmacloudHeaderBar)
