import { useState, useLayoutEffect, useEffect, MutableRefObject } from 'react'
import { $fetch, emitEvent } from '../utils'
import { URL_BASE } from '../config.ts'
import { Urls } from './urls'
import type { Environment } from '../config'
import type {
  ApplicationInterface,
  InformationInterface,
  LanguageType,
  OrganizationsInterface,
  TranslationsInterface
} from './auth.d.ts'

export type useAuthProps = {
  token?: string,
  env?: Environment,
  ref?: MutableRefObject<HTMLDivElement | null>,
  onSessionBlocked?: (blocked: boolean) => void
}

export type useAuthFail = {
  code: number,
  message: string,
  details: string,
} | null | undefined

export type userInfoData = {
  language: LanguageType,
  hasProfile: boolean,
  showLocker: boolean,
  loaded: boolean,
  current_delegation: string,
  applications: ApplicationInterface[],
  organizations: OrganizationsInterface,
  information: InformationInterface,
  translations: TranslationsInterface,
  maxFreeText: number[],
  colors: {
    primary?: string
    secondary?: string
  }
}

const DEFAULT_USER_INFO = {
  language: 'es' as LanguageType,
  hasProfile: false,
  showLocker: false,
  loaded: false,
  current_delegation: '',
  applications: [],
  organizations: {},
  information: {},
  translations: {},
  maxFreeText: [30, 30],
  colors: {}
}

let checkTokenInterval: ReturnType<typeof setTimeout>

export function useAuth ({
  token,
  env = 'dev',
  ref,
  onSessionBlocked
} : useAuthProps = {}) {
  const [pending, setPending] = useState<boolean>(false)
  const [fail, setFail] = useState<useAuthFail>()
  
  function sendError (payload: useAuthFail) {
    console.error(payload)
    setFail(payload)
    return payload
  }
  
  // URLS
  
  const [urls, setUrls] = useState<Urls>({} as Urls)

  async function getUrls () : Promise<Urls> {
    const fileName = `config.${env}.json?${new Date().getTime() + 3000}`
    const res = await fetch(URL_BASE + fileName)
    const data = await res.json()
    setUrls(data)
    return data
  }

  useLayoutEffect(() => {
    getUrls()
  }, [])

  // TOKEN

  const [userToken, setToken] = useState<string | null | undefined>(token)

  useEffect(() => {
    setToken(token)
  }, [token])

  useEffect(() => {
    resetCheckTimeout()
    if (userToken) {
      getUserInfo()
    } else {
      setUserInfo(DEFAULT_USER_INFO)
    }
  }, [urls, userToken])

  useEffect(() => {
    checkToken()
  }, [urls, userToken])

  // LOGOUT

  async function logout () {
    try {
      setFail(null)
      setPending(true)
      const url = urls[env as keyof typeof urls]?.url_logout as string
      if (!url || !userToken) return
      await $fetch({
        method: 'POST',
        url: `${url}?token=${userToken}`, body: { token: userToken },
        language: userInfo.language
      })
      setToken(null)
      emitEvent(ref?.current, 'logged-out')
    } catch (error: any) {
      return sendError({
        code: 500,
        message: 'Ha habido un error inesperado',
        details: String((error?.message) || error || '')
      })
    } finally {
      setPending(false)
    }
  }

  useEffect(() => {
    window.addEventListener('onLogout', logout)
    return () => {
      window.removeEventListener('onLogout', logout)
    }
  }, [userToken, urls])

  // USER INFO

  const [userInfo, setUserInfo] = useState<userInfoData>(DEFAULT_USER_INFO)

  async function getUserInfo (language: LanguageType = 'es') {
    try {
      setFail(null)
      setPending(true)
      const url = urls[env as keyof typeof urls]?.url_info as string
      if (!url || !userToken) return
      const data = await $fetch({
        url: `${url}?token=${userToken}`,
        language: userInfo.language || language
      })
      if (data.code === 401) {
        setToken(null)
        return sendError({
          code: 401,
          message: 'Unauthorized',
          details: ''
        })
      }
      const _language: LanguageType = (data.customization_info?.data?.language) || 'es'
      setUserInfo({
        loaded: true,
        hasProfile: !!data.has_profile,
        showLocker: !!data.switch_user,
        language: _language,
        current_delegation: data.current_delegation || '',
        applications: (data?.applications?.data) || [],
        organizations: (data.parent_cooperative_organizations?.data) || [],
        information: (data.customization_info?.data) || {},
        translations: (urls.translations?.[_language]) || {},
        maxFreeText: [
          (data.max_free_text_1_length) || 30,
          (data.max_free_text_2_length) || 30
        ],
        colors: {
          primary: data.customization_info?.data?.colors?.primary_color?.hex_color,
          secondary: data.customization_info?.data?.colors?.secondary_color?.hex_color
        }
      })
      emitEvent(ref?.current, 'logged-in')
      return data
    } catch (error: any) {
      return sendError({
        code: 500,
        message: 'Ha habido un error inesperado',
        details: String((error?.message) || error || '')
      })
    } finally {
      setPending(false)
    }
  }

  // Check token

  function resetCheckTimeout () {
    clearTimeout(checkTokenInterval)
    checkTokenInterval = setTimeout(() => checkToken(), urls.polling_timeout_ms || 10000)
  }

  async function checkToken () {
    try {
      setFail(null)
      setPending(true)
      clearTimeout(checkTokenInterval)
      const url = urls[env as keyof typeof urls]?.url_checktoken as string
      if (document.hidden || !url || !userToken) return
      const data = await $fetch({ url: `${url}?token=${userToken}`, language: userInfo.language })
      if (data.code === 401) {
        setToken(null)
        emitEvent(ref?.current, 'logged-out')
        return sendError({
          code: 401,
          message: 'Unauthorized',
          details: ''
        })
      }
      // Check if session is changed
      if (data.jti && data.jti !== userToken) setToken(data.jti)
      // Check if session is blocked
      onSessionBlocked && onSessionBlocked(data.session_blocked)
      return { ...data, code: 200 }
    } catch (error: any) {
      return sendError({
        code: 500,
        message: 'Ha habido un error inesperado',
        details: String((error?.message) || error || '')
      })
    } finally {
      setPending(false)
      resetCheckTimeout()
    }
  }

  // Adding an event listener to the document to check if bar self browser tab is active.
  useEffect(() => {
    document.addEventListener('visibilitychange', checkToken)
    return () => {
      document.removeEventListener('visibilitychange', checkToken)
    }
  }, [urls, userToken])

  // Change language

  function changeLanguage (event: Event & { detail?: LanguageType }) {
    if (event?.detail !== userInfo.language) {
      getUserInfo(event?.detail)
    }
  }

  useEffect(() => {
    window.addEventListener('change-lang', changeLanguage)
    return () => {
      window.removeEventListener('change-lang', changeLanguage)
    }
  }, [])

  return {
    userToken,
    pending,
    fail,
    urls,
    userInfo,
    getUrls,
    setUrls,
    getUserInfo,
    checkToken,
    resetCheckTimeout,
    setToken,
    logout
  }
}