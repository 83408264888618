import * as styles from './styles'
import type { Environment, Theme, TopbarMenu, TopbarMenuItem, Delegations } from './config'

// Components
import { Box, Btn, Text, Icon } from './components/common'
import { Clock } from './components/Clock'
import { ProfileDropdown } from './components/ProfileDropdown'
import { ConfigDropdown } from './components/ConfigDropdown'
import { OrganizationsModal } from './components/OrganizationsModal'
import { MarketModal } from './components/MarketModal'
import { SearchModal } from './components/SearchModal'
import { LockScreenModal } from './components/LockScreenModal'
import { SecurityMessage } from './components/SecurityMessage'
// Hooks
import { useAuth } from './hooks/useAuth'
import { useLockScreen } from './hooks/useLockScreen'
import { useOrganizations } from './hooks/useOrganizations'
import { useMarketModal } from './hooks/useMarketModal'
import { useDelegations } from './hooks/useDelegations'
// Utils
import { useRef, useEffect, useState, useMemo } from 'react'
import { emitEvent } from './utils'

export interface AppProps {
  hide?: boolean
  env: Environment
  reload?: boolean
  token?: string
  freeText1?: string
  freeText2?: string
  showDateTime: boolean
  showHelp?: boolean
  showConfiguration?: boolean
  showFindEngine?: boolean
  showLocker?: boolean
  encodeFindEngineText?: boolean
  showFindEngineTextBox?: boolean
  dropdownMenu?: TopbarMenu
  showShoppingCart: boolean
  shoppingCartItems: number
  showFavorites?: boolean
  primaryColor?: string
  theme: Theme
  delegations?: Delegations
  secured?: boolean
}

type toolButton = {
  id: string | null | undefined,
  Component?: JSX.Element,
  Icon?: JSX.Element,
  action?: () => void,
  bullet?: number | string
} | false | null | undefined

const DISABLE_SWITCH_USER = process.env.DISABLE_SWITCH_USER === '1'

export function App({
  hide,
  env,
  token,
  freeText1,
  freeText2,
  showDateTime,
  showHelp,
  showConfiguration,
  showFindEngine,
  showFindEngineTextBox,
  showFavorites,
  encodeFindEngineText,
  showShoppingCart,
  shoppingCartItems,
  primaryColor,
  dropdownMenu,
  delegations,
  showLocker,
  theme,
  secured
}: AppProps) {

  const ref = useRef<HTMLDivElement>(null)

  // ORGNIZATIONS

  const {
    openModalOrganizations,
    pending: changingOrganization,
    switchOrganization,
    setOpenModalOrganizations
  } = useOrganizations({ ref })

  // MARKET MODAL

  const {
    openMarketModal,
    setOpenMarketModal
  } = useMarketModal({ ref })

  // AUTH

  const {
    userToken,
    setToken,
    resetCheckTimeout,
    logout,
    userInfo,
    urls
  } = useAuth({
    token,
    env,
    ref,
    onSessionBlocked (blocked: boolean) {
      if (DISABLE_SWITCH_USER) return
      setShowLockScreen(blocked)
      if (blocked) { // Close all modals
        setOpenMarketModal(false)
        setOpenModalOrganizations(false)
        setShowSearchModal(false)
      }
    }
  })

   // LOCK SCREEN

   const {
    showLockScreen,
    LockScreenValid,
    LockScreenDisabled,
    lockScreenValue,
    setShowLockScreen,
    unlockScreen,
    blockSession
  } = useLockScreen({
    ref,
    env,
    urls,
    onChangeToken: (token: string) => {
      setToken(token)
      resetCheckTimeout()
    }
  })

  // SEARCH

  const [showSearchModal, setShowSearchModal] = useState<boolean>(false)

  useEffect(() => {
    setShowSearchModal(false)
  }, [showFindEngineTextBox])

  // DELEGATIONS MENU

  const {
    activeDelegation,
    setActiveDelegation,
    delegationsMenu
  } = useDelegations({
    delegations,
    userInfo
  })

  // BUTTONS

  const toolButtons = useMemo(() => {
    return [
      delegationsMenu && {
        id: 'delegations',
        Component: (
          <ConfigDropdown
            {...delegationsMenu}
            icon="icon-pharmacy"
            theme={theme}
            onClickItem={(item: TopbarMenuItem) => {
              const uuid = item.data?.uuid
              if (item.type === 'menu' || uuid === activeDelegation?.uuid) return
              setActiveDelegation(item.data)
              emitEvent(ref?.current, 'change-delegation', uuid)
            }}
          />
        )
      },
      showShoppingCart && {
        id: 'shopping-cart',
        Icon: <Icon name="icon-shopping-cart-outline" size="xl"/>,
        action: () => emitEvent(ref?.current, 'shopping-cart-pressed', shoppingCartItems),
        bullet: shoppingCartItems
      },
      showFavorites && {
        id: 'favorites',
        Icon: <Icon name="icon-heart-outline" size="xl"/>,
        action: () => emitEvent(ref?.current, 'favorites-button-pressed')
      },
      showHelp && {
        id: 'help',
        Icon: <Icon name="icon-question-mark-circle-outline" size="xl"/>,
        action: () => emitEvent(ref?.current, 'help-button-pressed')
      },
      showConfiguration && {
        id: 'config',
        Icon: <Icon name="icon-settings-2-outline" size="xl"/>,
        action: () => emitEvent(ref?.current, 'configuration-button-pressed')
      },
      dropdownMenu && {
        id: 'dropdown',
        Component: (
          <ConfigDropdown
            {...dropdownMenu}
            theme={dropdownMenu.theme || theme}
            onClickItem={(item: TopbarMenuItem) => {
              if (!item.eventName) return
              emitEvent(ref?.current, `config-${item.eventName}`, item)
            }}
          />
        )
      },
      (showLocker || userInfo.showLocker) && !DISABLE_SWITCH_USER && {
        id: 'locker',
        Icon: <Icon name="icon-lock-outline" size="xl"/>,
        action: () => {
          blockSession(userToken as string)
          setShowLockScreen(true)
          emitEvent(ref?.current, 'screen-locked-by-topbar')
        }
      },
      showFindEngine && {
        id: 'search',
        Icon: <Icon name="icon-search-outline" size="xl"/>,
        action: () => {
          setShowSearchModal(true)
          emitEvent(ref?.current, 'find-engine-pressed')
        }
      }
    ].map((tool: toolButton, index) => {
      if (!tool) return null
      if (tool.Component) return tool.Component
      return (
        <Btn
          key={index}
          data-test={tool.id}
          className="rounded-0"
          variant="tonal"
          color="base"
          width="50"
          height="100%"
          bullet={tool.bullet}
          onClick={tool.action}
        >
          {tool.Icon}
        </Btn>
      )
    })
  }, [
    showHelp,
    showConfiguration,
    showFindEngine,
    dropdownMenu,
    delegationsMenu,
    theme,
    showLocker,
    showShoppingCart,
    shoppingCartItems,
    showFavorites,
    userInfo
  ])

  // PROFILE

  function onClickProfile () {
    const isBlank = window.location?.origin === urls[env]?.url_origin ? '_self' : '_blank'
    window.open(urls[env]?.profile, isBlank)
  }

  function onClickPharmacy () {
    const isBlank = window.location.hostname === urls[env]?.url_origin ? '_self' : '_blank'
    window.open(urls[env]?.my_pharmacy, isBlank)
  }

  // STYLES

  const isVisibleBar = useMemo(
    () => !!(!hide && userInfo.loaded),
    [userInfo, hide]
  )

  const computedStyles = useMemo(() => {
    const { colors, ...styleModules } = styles
    return [
      ...Object.values(styleModules),
      colors({
        primary: primaryColor || userInfo.colors?.primary || '',
        secondary: userInfo.colors?.secondary || ''
      })
    ].join('\n')
  }, [userInfo, primaryColor])

  useEffect(() => {
    emitEvent(ref?.current, isVisibleBar ? 'onShowBar' : 'onHideBar')
  }, [isVisibleBar])

  return (
    <>
      <style>{computedStyles}</style>
      {userToken && !DISABLE_SWITCH_USER && (
        <LockScreenModal
          active={showLockScreen}
          value={lockScreenValue}
          valid={LockScreenValid}
          disabled={LockScreenDisabled}
          userInfo={userInfo}
          onClickLogout={logout}
          onChange={(pin: string) => unlockScreen({
            url: urls[env]?.url_switch_user_pin as string,
            token: userToken as string,
            language: userInfo.language,
            pin
          })}
        />
      )}
      {userToken && (
        <MarketModal
          active={openMarketModal}
          userInfo={userInfo}
          onClose={() => setOpenMarketModal(false)}
        />
      )}
      {userToken && (
        <OrganizationsModal
          active={openModalOrganizations}
          userInfo={userInfo}
          changingOrganization={changingOrganization}
          onClose={() => setOpenModalOrganizations(false)}
          onSwitchOrganization={(uuid: string) => switchOrganization({
            url: urls[env]?.url_switch_organization as string,
            token: userToken as string,
            language: userInfo.language,
            uuid
          })}
        />
      )}
      {userToken && showFindEngineTextBox && (
        <SearchModal
          active={showSearchModal}
          theme={theme}
          encodeFindEngineText={encodeFindEngineText}
          onClose={() => setShowSearchModal(false)}
          onSubmit={(text: string) => {
            emitEvent(ref?.current, 'find-engine-submit', text)
            setShowSearchModal(false)
          }}
        />
      )}
      <Box
        ref={ref}
        className="toolbar-app"
        theme="dark"
        bgColor="background"
        color="base"
        height={48}
        style={{ display: isVisibleBar ? 'flex' : 'none' }}
      >
        <Btn
          className="rounded-0"
          color="primary"
          width="48"
          height="100%"
          data-test="market"
          onClick={() => userToken && setOpenMarketModal(true)}
        >
          <Icon name="icon-market" size="15"/>
        </Btn>
        <Text className="ml-5" weight="medium">
          Cloud
        </Text>
        {freeText1 && (
          <>
            <span className="topbar-divider mx-4"></span>
            <Text weight="bold" data-test="freetext-1">
              {freeText1.substring(0, userInfo.maxFreeText[0] as number)}
            </Text>
          </>
        )}
        {showDateTime && userInfo.loaded && (
          <>
            <span className="topbar-divider mx-4"></span>
            <Clock language={userInfo.information?.language ?? 'es'}  data-test="date-time"/>
          </>
        )}
        {freeText2 && (
          <>
            <span className="topbar-divider mx-4"></span>
            <Text data-test="freetext-2">
              {freeText2.substring(0, userInfo.maxFreeText[1] as number)}
            </Text>
          </>
        )}
        <div className="flex-grow-1"/>
        {userInfo.loaded && toolButtons}
        {userInfo.loaded && (
          <ProfileDropdown
            className="flex-shrink-1 mr-5 ml-1"
            userInfo={userInfo}
            theme={theme}
            onClickProfile={onClickProfile}
            onClickPharmacy={onClickPharmacy}
            onClickLogout={logout}
            onClickEnv={() => setOpenModalOrganizations(true)}
          />
        )}
      </Box>
      {isVisibleBar && secured && (
        <SecurityMessage />
      )}
    </>
  )
}
